import React, { useState } from "react";
import { NavLink as Link } from "react-router-dom";
import "./nav.scss";
import SVG from "react-inlinesvg";
import HamburgerMenu from "react-hamburger-menu";
import ScrollLock, { TouchScrollable } from "react-scrolllock";
// import {
//   isMobile,
//   isTablet,
//   MobileView,
//   BrowserView,
// } from "react-device-detect";
import withSizes from "react-sizes";

const Logo = () => <SVG src={require("logo.svg")}></SVG>;
const LogoFull = () => <SVG src={require("logo-full.svg")}></SVG>;

const Nav = ({ showBurgerNav }) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <div className={`hello ${showBurgerNav && !!isOpen && "hide"}`}>
        A Partnership between{" "}
        <a
          href="https://milpacollective.org/"
          target="_blank"
          rel="noreferrer noopener"
        >
          The Milpa Collective
        </a>{" "}
        and{" "}
        <a href="https://vera.org" target="_blank" rel="noreferrer noopener">
          Vera Institute of Justice
        </a>
      </div>
      <header className="header">
        <div className="nav">
          <div className="logo logo--full">
            <Link to={`/`} onClick={() => setIsOpen(false)}>
              {/* { <Logo />}  */}

              <LogoFull></LogoFull>
            </Link>
          </div>

          <div className="nav-links">
            {showBurgerNav ? (
              <HamburgerMenu
                isOpen={isOpen}
                menuClicked={handleClick}
                width={32}
                height={16}
                strokeWidth={4}
                rotate={0}
                color="#423e37"
                borderRadius={0}
                animationDuration={0.5}
              />
            ) : (
              <NavLinks></NavLinks>
            )}
          </div>
          {/* <div className="languages">
            <div className="language-picker">
              <ul>
                <li className="active">ENG</li>
                <li>ESP</li>
              </ul>
            </div>
          </div> */}
        </div>
        {showBurgerNav && isOpen && (
          <ScrollLock>
            <div className="mobile-nav">
              <NavLinks closeNav={handleClick}></NavLinks>
            </div>
          </ScrollLock>
        )}
      </header>
    </>
  );
};

const NavLinks = ({ closeNav }) => {
  // build nav/gql?
  return (
    <nav>
      <Link to={`/about`} onClick={closeNav}>
        About
      </Link>
      <Link to={`/our-approach`} onClick={closeNav}>
        Our Approach
      </Link>
      <Link to={`/where-we-work`} onClick={closeNav}>
        Where We Work
      </Link>
      <Link to={`/news`} onClick={closeNav}>
        News
      </Link>
      <Link
        to={`/blog`}
        onClick={closeNav}
        isActive={(match, location) => {
          console.log("Nav Match", match, location);
          if (match || location.pathname.indexOf("/blog") === 0) {
            return true;
          } else {
            return false;
          }
        }}
      >
        Blog
      </Link>
      <Link to={`/contact`} onClick={closeNav}>
        Contact
      </Link>
      {/* <Link to={`/get-involved`} onClick={closeNav}>
        Get Involved
      </Link> */}
    </nav>
  );
};

const mapSizesToProps = ({ width }) => ({
  showBurgerNav: width < 1024,
});

export default withSizes(mapSizesToProps)(Nav);
