import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./carousel.scss";
import ResponsiveImage from "components/responsive-image";
const settings = {
  dots: true,
  infinite: true,
  slidesToShow: 1,
  arrows: true,
  dotsClass: "rp-dots",
};

const Carousel = ({ heading, colorScheme, children }) => {
  const slides = children.map((slide, index) => (
    <Slide key={index} {...slide} className="slide__wrap"></Slide>
  ));

  return (
    <section className={`carousel`}>
      <div className={`carousel__heading colorScheme--bg-${colorScheme}`}>
        <div className="container">
          <h1>{heading}</h1>
        </div>
      </div>
      <div className="container">
        <Slider {...settings}>{slides}</Slider>
      </div>
      <div className={`carousel__footer colorScheme--bg-${colorScheme}`}></div>
    </section>
  );
};

const Slide = ({ richText, image }) => {
  return (
    <div className="carousel__slide">
      <div
        className="slide__content"
        dangerouslySetInnerHTML={{ __html: richText }}
      />
      <div className="slide__image">
        {image.length > 0 && (
          <ResponsiveImage
            imageObject={image[0]}
            aspectRatio="5x4"
          ></ResponsiveImage>
        )}
      </div>
    </div>
  );
};

const useCarousel = () => {};

export default Carousel;
