import React from "react";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";
import { image, flexBlocks, newsEntry, seomatic } from "helpers";
//import {  } from "components/blocks/flex-block/flex-helpers";

const locationQuery = gql`
query($siteId: String, $intId: Int, $entryId: [QueryArgument] , $relId: [Int], $uri: String) {
  entry(section: "locations", id: $entryId, siteId: $siteId) {
    id
    typeHandle
    uri
    slug
    ... on locations_state_Entry{
      title
      image{
        ${image}
      }
      slideoutBlocks{
        ... on slideoutBlocks_flexBlock_BlockType{
          typeHandle
          ${flexBlocks("slideoutBlocks")}
        }
      }
    }
    children {
      title
      ... on locations_site_Entry {
        city    
        image{
            ${image}
        }
        linkField{
          ariaLabel
          customText
          target
          text
          title
          type
          url
        }
      }
    }   
  }
  prevEntry: entry(prevSiblingOf: $intId, siteId: $siteId) {
    id
    title
    slug
    uri
  }
  nextEntry: entry(nextSiblingOf: $intId, siteId: $siteId) {
    id
    title
    slug
    uri
  }
  related:entries(relatedTo: $relId, siteId: $siteId, section:["news", "blog"]){
    ${newsEntry}
  }
  ${seomatic}
}
`;

const useLocations = (id) => {
  let variables = {
    entryId: id,
    relId: id,
    intId: id,
    uri: typeof window !== "undefined" ? window.location.pathname : null,
    siteId: "1",
  };
  return useQuery(locationQuery, { variables: variables });
};

export default useLocations;
