import React from "react";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";
import { image, flexBlocks, seomatic } from "helpers";
//import {  } from "components/blocks/flex-block/flex-helpers";

const teamQuery = gql`
query($siteId: String, $entryId: [QueryArgument], $uri: String ) {
  entry(section: "team", id: $entryId, siteId: $siteId) {
    id
    typeHandle
    uri
    slug
    ... on team_teamMember_Entry{ # teamMember
      title
      organization
      firstName
      lastName
      jobTitle
      image{
        ${image}
      }
      state {
        id
        title
        ...on locations_site_Entry{
          city
        }
        parent{
          title
        }
      }
      slideoutBlocks{
        ... on slideoutBlocks_flexBlock_BlockType{
          typeHandle
          ${flexBlocks("slideoutBlocks")}   
        }
      }
    }   
  }
  ${seomatic}
}
`;

const useLocations = (id) => {
  let variables = {
    entryId: id,
    uri: typeof window !== "undefined" ? window.location.pathname : null,
    siteId: "1",
  };
  return useQuery(teamQuery, { variables: variables });
};

export default useLocations;
