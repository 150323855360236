import React from "react";
import { NavLink } from "react-router-dom";
import { OutboundLink } from "react-ga";
import "./link.scss";

const Link = ({ link, style }) => {
  let url = link.url;
  if (!url) {
    return <>{console.warn("Missing URL")}</>;
  }
  if (link.type === "entry" && url) {
    let split = url.split("//");
    split = split.length > 1 ? split[1].split("/") : split;
    split.splice(0, 1);
    url = "/" + split.join("/");
  }

  const LinkType = link.type === "entry" ? NavLink : OutboundLink;
  const attrs = link.type === "entry" ? {} : {eventLabel : (url ? url : "null")}

  return (
    <LinkType
      to={url}
      className={style}
      target={link.target}
      rel={link.target ? "noreferrer noopener" : ""}
      {...attrs}
    >
      <span>{link.customText || link.text}</span>
    </LinkType>
  );
};

export default Link;
