import React from "react";
import useLocation from "components/blocks/locations/useLocation";
import useTeamMember from "components/blocks/team-members/useTeamMember";
import Blocks from "components/blocks";
import ResponsiveImage from "components/responsive-image";
import { Tile as NewsTile } from "components/blocks/news";
import { NavLink } from "react-router-dom";
import SeomaticHelmet from "components/SeomaticHelmet";
import { isMobileOnly } from "react-device-detect";
import MobileSlider from "components/mobile-slider/";
import "./slideout.scss";

const SlideoutContent = ({ entryData }) => {
  return (
    <div>
      {entryData.typeHandle === "state" && (
        <LocationSlideout entryData={entryData}>loc slideout</LocationSlideout>
      )}
      {entryData.typeHandle === "teamMember" && (
        <PersonEntry entryData={entryData}></PersonEntry>
      )}
    </div>
  );
};

const LocationSlideout = ({ entryData, children }) => {
  const { loading, error, data } = useLocation(entryData.id);
  if (loading) {
    return <>{console.log("loading location")}</>;
  }
  if (error) {
    return <>{console.error("error loading location")}</>;
  }
  const location = data.entry;
  const nextEntry = data.nextEntry;
  const prevEntry = data.prevEntry;
  const related = data.related;
  const sites = location.children;

  const { slideoutBlocks, ...rest } = location;
  const slides = related.map((item, index) => {
    return (
      <React.Fragment key={index}>
        {item.typeHandle === "news" && <NewsTile item={item}></NewsTile>}
      </React.Fragment>
    );
  });
  return (
    <>
      <SeomaticHelmet seomatic={data.seomatic} />
      {location.image.length > 0 && (
        <ResponsiveImage
          aspectRatio="16x9"
          imageObject={location.image[0]}
        ></ResponsiveImage>
      )}
      <div className="slideout-content__inner">
        <h1>{location.title}</h1>
        <Blocks blocks={slideoutBlocks} entry={rest}></Blocks>
        {!!sites &&
          sites.map((site, index) => (
            <div className="" key={index}>
              {site.title}
              {site.linkField.url}
            </div>
          ))}

        {/* this should be a slider on mobile */}
        {related.length > 0 && (
          <>
            {isMobileOnly ? (
              <MobileSlider>{slides}</MobileSlider>
            ) : (
              <div className="tiles">{slides}</div>
            )}
          </>
        )}
        <nav className="section-nav">
          {prevEntry && (
            <NavLink to={`/${prevEntry.uri}`}>&larr; {prevEntry.title}</NavLink>
          )}
          {nextEntry && (
            <NavLink to={`/${nextEntry.uri}`}>{nextEntry.title} &rarr;</NavLink>
          )}
        </nav>
      </div>
    </>
  );
};

const PersonEntry = ({ entryData }) => {
  const { loading, error, data } = useTeamMember(entryData.id);
  const statesHash = {
    Alabama: "AL",
    Alaska: "AK",
    "American Samoa": "AS",
    Arizona: "AZ",
    Arkansas: "AR",
    California: "CA",
    Colorado: "CO",
    Connecticut: "CT",
    Delaware: "DE",
    "District of Columbia": "DC",
    "Federated States Of Micronesia": "FM",
    Florida: "FL",
    Georgia: "GA",
    Guam: "GU",
    Hawaii: "HI",
    Idaho: "ID",
    Illinois: "IL",
    Indiana: "IN",
    Iowa: "IA",
    Kansas: "KS",
    Kentucky: "KY",
    Louisiana: "LA",
    Maine: "ME",
    "Marshall Islands": "MH",
    Maryland: "MD",
    Massachusetts: "MA",
    Michigan: "MI",
    Minnesota: "MN",
    Mississippi: "MS",
    Missouri: "MO",
    Montana: "MT",
    Nebraska: "NE",
    Nevada: "NV",
    "New Hampshire": "NH",
    "New Jersey": "NJ",
    "New Mexico": "NM",
    "New York": "NY",
    "North Carolina": "NC",
    "North Dakota": "ND",
    "Northern Mariana Islands": "MP",
    Ohio: "OH",
    Oklahoma: "OK",
    Oregon: "OR",
    Palau: "PW",
    Pennsylvania: "PA",
    "Puerto Rico": "PR",
    "Rhode Island": "RI",
    "South Carolina": "SC",
    "South Dakota": "SD",
    Tennessee: "TN",
    Texas: "TX",
    Utah: "UT",
    Vermont: "VT",
    "Virgin Islands": "VI",
    Virginia: "VA",
    Washington: "WA",
    "West Virginia": "WV",
    Wisconsin: "WI",
    Wyoming: "WY",
  };
  if (loading) {
    return <>{console.log("loading location")}</>;
  }
  if (error) {
    return <>{console.error("error loading team member")}</>;
  }
  const member = data.entry;

  const { slideoutBlocks, ...rest } = member;

  let state = member.state[0] ? member.state[0].title : null;
  let site = null;
  let city = "";
  if (state && member.state[0].parent) {
    site = state;
    city = member.state[0].city;
    state = member.state[0].parent.title;
  }

  return (
    <>
      <SeomaticHelmet seomatic={data.seomatic} />
      <div className="slideout-content__inner">
        <div className="team-member__header">
          <div className="team-member__image">
            {member.image.length > 0 && (
              <ResponsiveImage
                aspectRatio="16x9"
                imageObject={member.image[0]}
              ></ResponsiveImage>
            )}
          </div>
          <div className="team-member__details">
            <div className="eyebrow">
              <span className="flag">{member.organization}</span>
              <h1>
                {member.firstName}
                <br />
                {member.lastName}
              </h1>
              <div className="jobTitle meta">{member.jobTitle}</div>
              {state && (
                <div className="location meta">
                  {site && (
                    <div className="site-name">
                      Cheshire Correctional Institution &mdash;
                    </div>
                  )}
                  {state && (
                    <div className="city,state">
                      {" "}
                      {city}, {statesHash[state]}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>

        <Blocks blocks={slideoutBlocks} entry={rest}></Blocks>
      </div>
    </>
  );
};

export default SlideoutContent;
