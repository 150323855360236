import React from "react";
import SVG from "react-inlinesvg";
import { OutboundLink } from "react-ga";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MailchimpSubscribe from "react-mailchimp-subscribe";

import {
  faYoutube,
  faFacebookSquare,
  faTwitter,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";

import "./footer.scss";

const Milpa = () => <SVG src={require("assets/milpa.svg")}></SVG>;
const Vera = () => <SVG src={require("assets/vera.svg")}></SVG>;

function index() {
  const url = `//vera.us8.list-manage.com/subscribe/post?u=6542df2be696ba0ea2f17b66a&id=b4fdeb7fe9`;
  return (
    <footer className="page-footer">
      <div className="footer-top">
        <div className="container">
          <div className="mc-signup">
            <h4>Join Our Newsletter</h4>
            <MailchimpSubscribe
              url={url}
              render={({ subscribe, status, message }) => (
                <CustomForm
                  status={status}
                  message={message}
                  onValidated={(formData) => subscribe(formData)}
                />
              )}
            />
          </div>
          <div className="links">
            <ul>
              <li>
                <a href="/contact">Contact</a>
              </li>
              {/* <li>
                <a href="/land-acknowledgement">Land Acknowledgement</a>
              </li>
              <li>
                <a href="">Donate</a>
              </li> */}
            </ul>
          </div>
        </div>
      </div>

      <div className="footer-bottom">
        <div className="container">
          <div className="logos">
            <OutboundLink
              to="https://milpacollective.org/"
              rel="noreferrer noopener"
              eventLabel={"https://milpacollective.org/"}
            >
              <Milpa></Milpa>
            </OutboundLink>
            <span className="vr"></span>
            <OutboundLink
              to="https://www.vera.org/"
              rel="noreferrer noopener"
              eventLabel="https://www.vera.org/"
            >
              <Vera></Vera>
            </OutboundLink>
          </div>

          <ul className="social-links">
            <li>
              <a
                href="https://twitter.com/verainstitute"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faTwitter} />
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/verainstitute/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faInstagram} />
              </a>
            </li>
            <li>
              <a
                href="https://www.facebook.com/verainstitute/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faFacebookSquare} />
              </a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/user/verainst"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faYoutube} />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
}

const CustomForm = ({ status, message, onValidated }) => {
  let email, name;
  const submit = () =>
    email &&
    email.value.indexOf("@") > -1 &&
    onValidated({
      EMAIL: email.value,
    });

  return (
    <div className="mc-form">
      <input
        ref={(node) => (email = node)}
        type="email"
        placeholder="Your email"
      />
      <button onClick={submit}>Submit</button>
      {status === "sending" && <div className="mc-sending">sending...</div>}
      {status === "error" && (
        <div
          className="mc-error"
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}
      {status === "success" && (
        <div
          className="success"
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}
    </div>
  );
};

export default index;
