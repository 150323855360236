import React from "react";
import CForm from "components/contact-form/";

function ContactForm() {
  return (
    <div>
      <CForm></CForm>
    </div>
  );
}

export default ContactForm;
