import React from "react";
import MobileSlider from "components/mobile-slider/";
import { isMobileOnly } from "react-device-detect";
import Tile from "./tile";

const Tiles = ({ news }) => {
  const slides = news.map((item, index) => {
    return (
      <React.Fragment key={index}>
        <Tile item={item}></Tile>
      </React.Fragment>
    );
  });
  return (
    <div>
      {isMobileOnly ? (
        <MobileSlider>{slides}</MobileSlider>
      ) : (
        <div className={`tiles container tile-count-${slides.length}`}>
          {slides}
        </div>
      )}
    </div>
  );
};

export default Tiles;
