//import { flex } from "components/blocks/flex-block/flex-helpers";

export const breakpoints = {
  xs: "@media (max-width: 520px)",
  sm: "@media (min-width: 768px)",
  md: "@media (min-width: 1024px)",
  lg: "@media (min-width: 1200px)",
  xl: "@media (min-width: 1440px)",
};

const imageProps = `
    originalImageWidth
    originalImageHeight
    placeholder
    placeholderSvg
    lightness
    placeholderWidth
    placeholderHeight
    maxSrcsetWidth
    placeholderImage
    placeholderSilhouette
    src
    srcWebp
    srcset
    srcsetWebp
    placeholderBox
`;
export const image = `
...on assets_Asset{
  id
  extension
  url
  optimizedOriginal {
    ${imageProps}
  }
  optimized169 {
    ${imageProps}
  }
  optimized54 {
    ${imageProps}
  }
}
`;

export const ctas = `
  ctas{
    ...on ctas_cta_BlockType{
      linkStyle
      cta {
        ariaLabel
        customText
        target
        text
        title
        type
        url
      }
    }
  }
`;

const contactForm = `
  ...on contentBlocks_contactForm_BlockType{
    typeHandle
    id
  }
`;

export const flexBlockChildren = (contentType) => {
  if (contentType === undefined) {
    contentType = "contentBlocks";
  }

  return `
  ...on ${contentType}_text_BlockType{
    typeHandle
    richText
  }
  ...on ${contentType}_ctas_BlockType{
    typeHandle
    contentAlignment
    ${ctas}
  }
  ${contentType === "contentBlocks" ? contactForm : ""}
  
  ...on ${contentType}_image_BlockType{
    typeHandle
    
    ${contentType === "contentBlocks" ? "aspectRatio" : ""}
    ${contentType === "contentBlocks" ? border : ""}
    image{
        ${image}
    }
  }
  `;
};

const border = `
border{
      ...on border_border_BlockType{
        borderColor
      }
    }
`;

export const flexBlocks = (blockType) => `
children{
  typeHandle
    ...on ${blockType}_contentRow_BlockType{
      typeHandle
      ${blockType === "contentBlocks" ? "verticalAlignment" : ""}
      ${blockType === "contentBlocks" ? "overlapTop" : ""}
      children{
        ...on ${blockType}_contentColumn_BlockType{
          typeHandle
          mobileDisplayOrder
          children{
            ${flexBlockChildren(blockType)}
          } 
        }
      }
    }
  }
`;
export const seomatic = `
  seomatic(uri: $uri) {
    metaTitleContainer
    metaTagContainer
  }
`;

export const flex = `
 ...on contentBlocks_flexBlock_BlockType{
  typeHandle
  colorScheme
  borderArt
  ${flexBlocks("contentBlocks")}
}
`;

export const newsEntry = `
... on news_news_Entry {
  typeHandle
  linkUrl
  title
  description
  image {
    ${image}
  }
  date
  newsOutlet{
    ...on newsOutlet_Category{
      title
    }
  }
  newsCategory{
    ...on newsCategory_Category{
      title
    }
  }
}
`;

const blogFields = `
title
description
dateCreated
date
uri
image{
  ${image}
}
blogEntryType{
  ...on blogEntryType_Category{
    title
  }
}
blogSource{
  ...on blogSource_Category{
    title
  }
}

`;
export const contentBlocks = `
contentBlocks{
  ${flex}
  ...on contentBlocks_imageFullWidth_BlockType{
    typeHandle
    image {
      ${image}
    } 
  }
  ...on contentBlocks_quadrants_BlockType{
    id
    typeHandle
    children{
      ...on contentBlocks_quadrant_BlockType{
        border{
          ...on border_border_BlockType{
            borderColor
          }
        }
        richText
      }
    }
  }
  ... on contentBlocks_locationsMap_BlockType{
    typeHandle
    id
  }
  ... on contentBlocks_locationTiles_BlockType{
    typeHandle
    id
  }
  ... on contentBlocks_newsBlock_BlockType {
    typeHandle
    news {
      ${newsEntry}
    }
  }
  ...on contentBlocks_carousel_BlockType{
    typeHandle
    colorScheme
    heading
    children{
      ...on contentBlocks_slide_BlockType{
        typeHandle
        richText
        image{
          ${image}
        }
      }
    }
  }
  ...on contentBlocks_blogPosts_BlockType{
    typeHandle
    blogPosts {
      id
      ...on blog_externalBlogEntry_Entry{
        ${blogFields}
        linkUrl
        typeHandle
      }
      ...on blog_blog_Entry{
        ${blogFields}
        typeHandle
      }
    }
  }
  ...on contentBlocks_teamBlock_BlockType{
    typeHandle
    teamMembers {
      typeHandle
      ...on team_teamMember_Entry{
        id
        uri
        slug
        firstName
        lastName
        organization
        jobTitle
        level
        image {
          ${image}
        }
        state {
          id
          title
          parent{
            title
          }
        }
      }
    }
  }
}
`;
