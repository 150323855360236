import React, { useState, memo } from "react";
import Map from "./map";
import ReactTooltip from "react-tooltip";
import useLocations from "../locations/useLocations";

// const locations = [];

const tooltipSettings = {
  //   offset: { top: "-100%" },
  type: "light",
  border: true,
  borderColor: "#000",
  insecure: true,
  html: true,
  place: "right",
  clickable: true,
};

const LocationMap = () => {
  const [content, setContent] = useState("");
  const { loading, error, data } = useLocations();

  if (loading) {
    return <>loading locations</>;
  }
  if (error || !data) {
    return <>error loading locations</>;
  }
  if (data.entries.length < 1) {
    return <>{console.log("no locations found")}</>;
  }
  console.log("locations", data);

  //   useLocations;

  return (
    <div className="container">
      <ReactTooltip {...tooltipSettings}>{content}</ReactTooltip>
      <Map setTooltipContent={setContent} locations={data.entries}></Map>
    </div>
  );
};

export default memo(LocationMap);
