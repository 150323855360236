import React from "react";
import "./responsive-image.scss";

const ResponsiveImage = ({ imageObject, aspectRatio, banner }) => {
  let srcSet = null;
  let src = null;

  if (imageObject.extension !== "svg") {
    switch (aspectRatio) {
      case "original":
        srcSet = imageObject.optimizedOriginal.srcset;
        src = imageObject.optimizedOriginal.placeholderBox;
        break;
      case "5x4":
        srcSet = imageObject.optimized54.srcset;
        src = imageObject.optimized54.placeholderBox;
        break;
      default:
        srcSet = imageObject.optimized169.srcset;
        src = imageObject.optimized169.placeholderBox;
    }
  }
  return (
    <div className="image-block">
      <img
        banner={banner}
        src={imageObject.extension === "svg" ? imageObject.url : src}
        srcSet={srcSet}
        // data-src={imageObject.url}
        // data-srcset={srcSet}
        className={"ResponsiveImage lazyload"}
      />
    </div>
  );
};

export default ResponsiveImage;
