import React from "react";
import { NavLink } from "react-router-dom";
import { isMobileOnly } from "react-device-detect";
import MobileSlider from "components/mobile-slider/";
import ResponsiveImage from "components/responsive-image";
import Slideout from "components/slideout/";

import "./tile.scss";
import withMobileSlider from "components/withMobileSlider";

const TeamMembers = ({ teamMembers }) => {
  const slides = teamMembers.map((member, index) => {
    let state = member.state[0] ? member.state[0].title : null;
    if (state && member.state[0].parent) {
      state = member.state[0].parent.title;
    }

    return (
      <div className="team__tile tile" key={index}>
        <div className="tile__top">
          <div className="state vert-2"></div>
          <div className="photo">
            {member.image.length > 0 && (
              <ResponsiveImage
                imageObject={member.image[0]}
                aspectRatio={"16x9"}
              ></ResponsiveImage>
            )}
            <div className="more">Read More</div>
          </div>
        </div>
        <div className="details tile__footer">
          <div className={`vert-2 organization ${member.organization}`}>
            {member.organization}
          </div>
          <div className="text">
            <h1>
              {member.firstName} {member.lastName}
            </h1>
            <div className="job-title">{member.jobTitle}</div>
          </div>
        </div>
        <NavLink
          className="tile__link"
          to={{ pathname: `/${member.uri}` }}
        ></NavLink>
      </div>
    );
  });

  const slideouts = teamMembers.map((person, index) => {
    return (
      <Slideout
        key={index}
        slug={person.slug}
        entry={person}
        id={person.id}
        type={person.typeHandle}
      />
    );
  });

  return (
    <div>
      {isMobileOnly ? (
        <MobileSlider>{slides}</MobileSlider>
      ) : (
        <div className="container tiles team">{slides}</div>
      )}
      {slideouts}
    </div>
  );
};

export default withMobileSlider(TeamMembers);
