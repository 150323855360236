import React from "react";
import { MobileOnlyView, BrowserView, isMobileOnly } from "react-device-detect";
import MobileSlider from "components/mobile-slider/";

const settings = {
  dots: true,
  infinite: true,
  slidesToShow: 1,
  arrows: false,
  dotsClass: "rp-dots",
};

const Quadrant = ({ children }) => {
  return (
    <div className="quadrant">
      <div className="quadrant__inner">
        <div className="quadrant__content">{children}</div>
      </div>
    </div>
  );
};
const Quadrants = ({ children }) => {
  const slides = children.map((quadrant, index) => {
    const borderColor = quadrant.border.length
      ? quadrant.border[0].borderColor
      : "";
    return (
      <div className={`mobile-quadrant quadrant ${borderColor}`} key={index}>
        <div class="mobile-quadrant__content">
          <div dangerouslySetInnerHTML={{ __html: quadrant.richText }} />
        </div>
      </div>
    );
  });

  const quadrantRows = children
    .reduce((acc, curr, index) => {
      //acc.push(index);

      if (index % 2 === 0) {
        acc.push([]);
      }
      acc[acc.length - 1].push(curr);

      return acc;
    }, [])
    .map((row, index) => {
      return (
        <div key={index} className="quadrants__row">
          {row.map((quadrant) => {
            const borderColor = quadrant.border.length
              ? quadrant.border[0].borderColor
              : "";
            return (
              <div className={`quadrant ${borderColor}`}>
                <div className="quadrant__inner">
                  <div
                    className="quadrant__content"
                    dangerouslySetInnerHTML={{ __html: quadrant.richText }}
                  />
                </div>
              </div>
            );
          })}
        </div>
      );
    });

  return (
    <div className="container quadrants">
      {isMobileOnly ? (
        <MobileSlider>{slides}</MobileSlider>
      ) : (
        <div>{quadrantRows}</div>
      )}
    </div>
  );
};

export default Quadrants;
