import React from "react";
import ResponsiveImage from "components/responsive-image";
import "./hero.scss";

function index({ blocks }) {
  console.log("HERO", blocks);
  const hero = blocks && blocks.length ? blocks[0] : null;
  if (!hero) {
    return null;
  }

  const image = hero.image.length ? hero.image[0] : null;

  return (
    <section className="hero">
      {image && (
        <div className="hero__image">
          <ResponsiveImage
            imageObject={image}
            aspectRation="16x9"
          ></ResponsiveImage>
        </div>
      )}
      <div className="outer">
        <div className="heading">
          <h1>{hero.heading}</h1>
          {hero.subheading && (
            <div
              className="subheading"
              dangerouslySetInnerHTML={{ __html: hero.subheading }}
            />
          )}
        </div>
      </div>
    </section>
  );
}

export default index;
