import React from "react";
import ResponsiveImage from "components/responsive-image";
import Moment from "react-moment";

const Tile = ({ item }) => {
  const outlet = item.newsOutlet.length ? item.newsOutlet[0].title : "";
  const type = item.newsCategory.length ? item.newsCategory[0].title : "";

  let linkText = "";
  switch (type) {
    case "Video Essay":
      linkText = "Watch Now";
      break;
    case "Audio":
      linkText = "Listen Now";
      break;
    default:
      linkText = "Read Now";
  }
  return (
    <div className="tile">
      <div className="eyebrow">{type}</div>
      <div className="tile__mid">
        <div className="vert-2 source">{outlet}</div>
        <div className="mid__wrap">
          <div className="heading">{item.title}</div>
          <div className="image">
            {item.image.length > 0 && (
              <ResponsiveImage
                imageObject={item.image[0]}
                aspectRatio={"16x9"}
              ></ResponsiveImage>
            )}
            <div className="more">{linkText}</div>
          </div>
        </div>
      </div>
      <div className="tile__footer">
        <div className="date vert-2">
          <Moment format="MM . DD . Y">{item.date}</Moment>
        </div>
        <div className="vert-2 after"></div>
        <div className="text">{item.description}</div>
      </div>
      <a
        href={item.linkUrl}
        className="tile__link"
        target="_blank"
        rel="noreferrer noopener"
      ></a>
    </div>
  );
};

export default Tile;
