import React from "react";
import flexBlock from "./flex-block/";
import imageFullWidth from "./image-full-width/";
import teamBlock from "./team-members/";
import newsBlock from "./news/";
import quadrants from "./quadrants/";
import locationsMap from "./locations-map/";
import locationTiles from "./locations/";
import carousel from "./carousel/";
import blogPosts from "./blog-posts/";
// import quadrants from "./quadrants/";

const components = {
  flexBlock,
  imageFullWidth,
  teamBlock,
  newsBlock,
  quadrants,
  locationsMap,
  locationTiles,
  quadrants,
  carousel,
  blogPosts,
};

const Blocks = ({ blocks, entry }) => {
  return blocks.map((block, index) => {
    const Comp = components[block.typeHandle];
    if (!Comp) {
      console.warn(`Missing block type ${block.__typename}`);
      return null;
    }
    return <Comp key={index} {...block} entry={entry} />;
  });
};

export default Blocks;
