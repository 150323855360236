import React, { useEffect } from "react";
import { Switch, Route, useHistory } from "react-router-dom";
import ReactGA from "react-ga";
import Page from "components/page/";
import Blog from "components/blog/";
import Nav from "components/nav";
import Footer from "components/footer";
import { Helmet } from "react-helmet";
import "components/tile/tile.scss"
// import {
//   LanguageContext,
//   useLanguageContext,
// } from "components/LanguageContext";
const GOOGLE_ANALYTICS_KEY = "UA-8958146-8";
ReactGA.initialize(GOOGLE_ANALYTICS_KEY, { debug: false });
if (typeof window !== "undefined") {
  ReactGA.pageview(window.location.pathname + window.location.search);
}

const App = () => {
  // const languageContext = useLanguageContext();
  const history = useHistory();
  useEffect(() => {
    history.listen((location, action) => {
      ReactGA.pageview(location.pathname + location.search);
      document.activeElement.blur();
    });
  }, [history]);
  return (
    <>
      <Helmet>
        {/* <title>Health Is Justice</title>
              <meta property="og:type" content="website" />
              <meta property="og:title" content="Health Is Justice" /> */}
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/assets/favicons/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/assets/favicons/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/assets/favicons/favicon-16x16.png"
        />
        <meta name="msapplication-TileColor" content="#da532c" />

        {/* <meta name="theme-color" content="#ffffff" /> */}
      </Helmet>
      <Nav></Nav>

      <Switch>
        <Route path={`/blog/:slug`} component={Blog}></Route>
        <Route path={`/:parent/:slug`} component={Page}></Route>
        <Route path={"/:slug?"} component={Page} />
      </Switch>
      <Footer></Footer>
    </>
  );
};

export default App;
