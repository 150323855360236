import React from "react";
import { MobileView, BrowserView } from "react-device-detect";
import MobileSlider from "components/mobile-slider/";

const withMobileSlider = (WrappedComponent) => {
  //   class WithMobileSlider extends React.Component {
  //     render() {
  //       return (
  //         <WrappedComponent name={`test`} {...this.props}></WrappedComponent>
  //       );
  //     }
  //   }
  //   return WithMobileSlider;
  return function WithMobileSliderComponent({ isMobile, ...props }) {
    if (!isMobile) return <WrappedComponent {...props} />;
    return <MobileSlider>Mobile Slider</MobileSlider>;
  };
};

export default withMobileSlider;
