import React, { useState } from "react";
// import HeroBlocks from "components/hero-blocks";
import Blocks from "components/blocks";
import useBlog from "./useBlog";
import { NavLink } from "react-router-dom";
import Moment from "react-moment";
import "./blog.scss";

const Blog = ({ match }) => {
  // const parent = match.params.parent;
  console.log("Blog Match", match);

  const { loading, error, data } = useBlog(match.params.slug);

  if (loading) {
    return <>loading</>;
  }
  if (error || !data) {
    return <>error</>;
  }
  const entry = data.entry;

  if (!entry) {
    console.log(data, match);
    return <>404 not found</>;
  }

  const { contentBlocks, ...rest } = entry;

  return (
    <div className="blog">
      <div className="blog__header">
        <div className="container">
          <div className="eyebrow">
            <span className="flag">Blog Post</span>
            <span className="date">
              <Moment format="M. D. Y">{entry.date}</Moment>
            </span>
          </div>
          <h1>{entry.title}</h1>
          <div className="blog__author">
            {entry.authorName}
          </div>
        </div>
      </div>
      <Blocks blocks={contentBlocks} entry={rest}></Blocks>
    </div>
  );
};

export default Blog;
