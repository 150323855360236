import React from "react";
import ResponsiveImage from "components/responsive-image";

const ImageFullWidth = ({ image }) => {
  const aspectRatio = "16x9";
  return (
    <div>
      {image[0] && (
        <ResponsiveImage
          imageObject={image[0]}
          aspectRatio={aspectRatio}
        ></ResponsiveImage>
      )}
    </div>
  );
};

export default ImageFullWidth;
