import React from "react";
import Link from "components/link";

import "./ctas.scss";

export const CTAs = ({ ctas, contentAlignment }) => {
  return (
    <div
      alignment={contentAlignment}
      className={`align-${contentAlignment} ctas`}
    >
      {ctas && ctas.map((cta, index) => <CTA key={index} link={cta}></CTA>)}
    </div>
  );
};

const CTA = ({ link, colorScheme }) => {
  if (link.cta === undefined) {
    return <>{console.warn("missing link")}</>;
  }
  return (
    <div className="cta">
      {link.linkStyle === "button" && (
        <Link link={link.cta} style={`btn btn--border`} />
      )}
      {link.linkStyle === "text" && <Link link={link.cta} />}
    </div>
  );
};

export default CTAs;
