import React, { useState, useLayoutEffect, useEffect, useContext } from "react";
import {
  Transition,
  TransitionGroup,
  CSSTransition,
} from "react-transition-group";
import styled from "styled-components";
import SlideoutContent from "./slideoutContent";
import { useHistory, useRouteMatch, Route, Switch } from "react-router-dom";
// import { SiteContext } from "../../contexts/SiteContext";
import ScrollLock, { TouchScrollable } from "react-scrolllock";
import SVG from "react-inlinesvg";
import { breakpoints } from "helpers";

const X = () => <SVG src={require("assets/X.svg")}></SVG>;

const Modal = styled.section`
  height: 100vh;
  width: 100vw;
  max-width: 1200px;
  overflow-y: auto;

  background: ${(props) => props.theme.backgroundColor};
  color: ${(props) => props.theme.textColor};
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10001;
  transition: 0.5s;
  transform: translateX(120%);
  ${breakpoints.sm} {
    width: 85%;
    right: 0;
    left: auto;
  }
  ${breakpoints.md} {
    width: 80%;
  }
  /* transform: ${(props) =>
    props.show ? "translateX(0%)" : "translateX(100%)"}; */
  /* opacity: ${(props) => (props.show ? "1" : "0")}; */
  .slideout-content {
    background-color: var(--white);

    border-color: ${(props) => props.theme.textColor};
    border-left: 1px solid;
    padding: 0 0 3rem;
    margin: 0;
    min-height: 100vh;
    border: 6px solid var(--yellow);

    & > div {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      min-height: 100vh;
    }

    .slideout-content__inner {
      padding: 0 1rem 5rem;
      border: 6px solid var(--yellow);
      background-color: var(--white);
      min-height: 100vh;
      margin-bottom: 7rem;
      ${breakpoints.sm} {
        margin-bottom: 0;
      }
    }
    .image-block + .slideout-content__inner {
      min-height: 50vh;
    }
    border-top: none;

    /* & > div {
      padding-bottom: 5rem;
    } */
    ${breakpoints.sm} {
      border-width: 32px;
      .slideout-content__inner {
        /* padding: 2rem; */
        padding: 4rem 1rem 5rem;
        border-width: 32px;
      }
      h2 {
        font-size: 2rem;
      }
    }
    ${breakpoints.lg} {
      padding: 0;
      margin: 0;
      .slideout-content__inner {
        padding: 3rem 4rem;
        margin-bottom: 0;
      }
      h2 {
        font-size: 3rem;
      }
    }
  }
  transform: ${({ state }) => {
    switch (state) {
      case "entered":
        return "translateX(0%)";
      // case "entering":
      //   return "translateY(50%)";
      case "exited":
        return "translateX(120%)";
    }
  }};
`;

const SlideoutMask = styled.div`
  content: "";
  transition: 0.25s;
  z-index: 9999;
  display: block;
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
  opacity: 0;
  opacity: ${({ state }) => {
    switch (state) {
      case "entered":
        return "1";
      // case "entering":
      //   return "translateY(50%)";
      case "exited":
        return "0";
    }
  }};
`;
const CloseButton = styled.button`
  background-color: var(--yellow);
  border: 0;

  color: ${(props) => props.theme.backgroundColor};
  z-index: 1;
  position: fixed;

  display: flex;
  justify-content: center;
  cursor: pointer;
  transition: 0.5s;

  left: 0rem;
  top: 0rem;
  width: 2.75rem;
  height: 2.75rem;
  z-index: 10000;
  padding: 0 0.5rem;

  svg {
    height: 100%;
    width: 100%;
  }

  ${breakpoints.sm} {
    //transform: translate(-100%,1rem);
  }
  &:hover {
    background-color: var(--green);
  }
  &:focus {
    outline: 2px dotted;
  }
`;

const Slideout = ({ close, children, slug, entry, uri }) => {
  const [init, setInit] = useState(false);

  //   const { siteHandle, siteSettings, primaryColorScheme } = useContext(
  //     SiteContext
  //   );

  //   const props = { theme: primaryColorScheme };

  //   Modal.defaultProps = props;
  //   CloseButton.defaultProps = props;

  const closeSlideout = () => {
    history.push("/" + match.params.parent);
  };

  useEffect(() => {
    if (!init) {
      setInit(true);
    }
  }, [init]);

  let match = useRouteMatch();
  const history = useHistory();
  console.log("Slideout", match);

  return (
    <Route
      render={({ location }) => {
        const { pathname, key } = location;
        console.log(history.length);
        return (
          <TransitionGroup
            component={null}
            in={init}
            enter={true}
            mountOnEnter={true}
            unmountOnExit={true}
          >
            <Transition key={key} timeout={{ enter: 0, exit: 500 }}>
              {(state) => (
                <Switch location={location}>
                  <Route path={`/${match.params.parent}/${slug}`}>
                    <SlideoutMask state={state}></SlideoutMask>
                    <ScrollLock>
                      <Modal id={`popout`} state={state}>
                        <CloseButton onClick={closeSlideout}>
                          <X />
                        </CloseButton>
                        <div className="slideout-content">
                          <SlideoutContent entryData={entry}></SlideoutContent>
                        </div>
                      </Modal>
                    </ScrollLock>
                  </Route>
                </Switch>
              )}
            </Transition>
          </TransitionGroup>
        );
      }}
    ></Route>
  );
};

export default Slideout;
