import React from "react";
import { isMobileOnly } from "react-device-detect";
import MobileSlider from "components/mobile-slider/";

import ResponsiveImage from "components/responsive-image";
import { Link } from "react-router-dom";
import Moment from "react-moment";

const Tiles = ({ blogPosts }) => {
  const slides = blogPosts.map((item, index) => {
    return (
      <React.Fragment key={index}>
        <Tile item={item}></Tile>
      </React.Fragment>
    );
  });
  return (
    <div>
      {isMobileOnly ? (
        <MobileSlider>{slides}</MobileSlider>
      ) : (
        <div className={`tiles container tile-count-${slides.length}`}>
          {slides}
        </div>
      )}
    </div>
  );
};

const Tile = ({ item }) => {
  const outlet = item.blogSource.length ? item.blogSource[0].title : "";
  const type = item.blogEntryType.length ? item.blogEntryType[0].title : "";
  let linkText = "";
  switch (type) {
    case "Video":
      linkText = "Watch Now";
      break;
    case "Audio":
      linkText = "Listen Now";
      break;
    default:
      linkText = "Read Now";
  }
  return (
    <div className="tile">
      <div className="eyebrow">{type}</div>
      <div className="tile__mid">
        <div className="vert-2 source">{outlet}</div>
        <div className="mid__wrap">
          <div className="heading">{item.title}</div>
          <div className="image">
            {item.image.length > 0 && (
              <ResponsiveImage
                imageObject={item.image[0]}
                aspectRatio={"16x9"}
              ></ResponsiveImage>
            )}
            <div className="more">{linkText}</div>
          </div>
        </div>
      </div>
      <div className="tile__footer">
        <div className="date vert-2">
          <Moment format="MM . DD . Y">
            {item.date ? item.date : item.dateCreated}
          </Moment>
        </div>
        <div className="vert-2 after"></div>
        <div className="text">{item.description}</div>
      </div>
      {item.typeHandle === "blog" && (
        <Link
          to={item.uri}
          className="tile__link"
          // target="_blank"
          // rel="noreferrer noopener"
        ></Link>
      )}
      {item.typeHandle === "externalBlogEntry" && (
        <a
          href={item.linkUrl}
          className="tile__link"
          target="_blank"
          rel="noreferrer noopener"
        ></a>
      )}
    </div>
  );
};

export default Tiles;
