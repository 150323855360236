import React from "react";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";
import { image } from "helpers";

const locationsQuery = gql`
  query($siteId: String) {
    entries(section: "locations", level: 1, siteId: $siteId) {
      id
      typeHandle
      title
      uri
      slug
      ... on locations_state_Entry {
        image{
          ${image}
        }
        richText
      }
      children {
        title
        ... on locations_site_Entry {
          city
          richText
        }
      }
    }
  }
`;

const useLocations = () => {
  let variables = {
    siteId: "1",
  };
  return useQuery(locationsQuery, { variables: variables });
};

export default useLocations;
