import React, { useState } from "react";
import "./form.scss";

const ContactForm = () => {
  const [message, setMessage] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [location, setLocation] = useState("");
  const [affiliation, seAffiliation] = useState("");
  const [buttonText, setButtonText] = useState("Submit");
  const [mail, setMail] = useState({});
  const formSubmit = (e) => {
    e.preventDefault();
    console.log("init submit");
    // fetch("http://localhost:3002/send", {
    fetch("https://restoringpromise.vera.org/mailer.php", {
      method: "POST",
      body: JSON.stringify({
        name: name,
        email: email,
        location: location,
        affiliation: affiliation,
        message: message,
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === "success") {
          alert("Message Sent.");
          resetForm();
        } else if (response.status === "fail") {
          alert("Message failed to send.");
          console.log(response);
        }
      });
  };
  const resetForm = () => {
    setEmail("");
    setMessage("");
    setName("");
    setLocation("");
  };
  // const setMessage = (message) => {
  //   setMail({ ...mail, message: message });
  // };
  const affiliations = [
    "Family Member",
    "Formerly Incarcerated Person",
    "Staff Person",
    "Community Member",
  ];
  return (
    <div>
      <form
        className="contact-form"
        onSubmit={(e) => formSubmit(e)}
        method="POST"
      >
        <div className="affiliations">
          {affiliations.map((senderAffiliation, index) => {
            const slug = senderAffiliation.replace(/ /g, "-").toLowerCase();
            return (
              <div key={index} className={`affiliation`}>
                <input
                  type="radio"
                  name="affiliation"
                  id={slug}
                  onChange={(e) => seAffiliation(e.target.value)}
                  value={senderAffiliation}
                ></input>

                <label htmlFor={slug}>
                  <span className="radio-with-border" />
                  {senderAffiliation}
                </label>
              </div>
            );
          })}
          <div></div>
        </div>

        <div className="form-row">
          <label className="message-name" htmlFor="message-name">
            Your Name
          </label>

          <input
            onChange={(e) => setName(e.target.value)}
            name="name"
            className="message-name"
            type="text"
            placeholder="Your Name"
            value={name}
          />
        </div>
        <div className="form-row">
          <div className="form-column">
            <label className="message-email" htmlFor="message-email">
              Your Email
            </label>
            <input
              onChange={(e) => setEmail(e.target.value)}
              name="email"
              className="message-email"
              type="email"
              placeholder="your@email.com"
              required
              value={email}
            />
          </div>
          <div className="form-column">
            <label className="location-email" htmlFor="location-email">
              Your Location
            </label>
            <input
              onChange={(e) => setLocation(e.target.value)}
              name="location"
              className="location-email"
              type="text"
              placeholder="Your Location"
              value={location}
            />
          </div>
        </div>
        <div className="form-row">
          <label className="message" htmlFor="message-input">
            Your Message
          </label>
          <textarea
            onChange={(e) => setMessage(e.target.value)}
            name="message"
            className="message-input"
            type="text"
            placeholder="Please write your message here"
            value={message}
            required
          />
        </div>
        <div className="button--container">
          <button type="submit" className="button button-primary">
            {buttonText}
          </button>
        </div>
      </form>
    </div>
  );
};

export default ContactForm;
