import React from "react";
// import { Container } from "../../components/layout";
// import { ContentRow } from "./styles";

const FlexRow = ({ moduleContent, overlap, children }) => {
  return (
    <div className={`contentRow ${overlap ? "neg-top-margin" : ""}`}>
      <div className="container">{children}</div>
    </div>
  );
};

export default FlexRow;
