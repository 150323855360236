import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const settings = {
  dots: true,
  infinite: true,
  slidesToShow: 1,
  arrows: false,
  dotsClass: "rp-dots",
};

function index({ children }) {
  return <Slider {...settings}>{children}</Slider>;
}

export default index;
