import React from "react";
import contentRow from "./content-row/";
import contentColumn from "./content-column/";
import text from "./text/";
import ctas from "./ctas/";
import image from "./image/";
import contactForm from "./contact-form/";
import "./flex-block.scss";

const flexBlocks = {
  contentRow,
  contentColumn,
  ctas,
  text,
  image,
  contactForm,
};

const FlexBlock = ({
  typeHandle,
  overlapTop,
  children,
  color,
  colorScheme,
  borderArt,
  entry,
}) => {
  console.log("overlap", overlapTop);
  //if (!children || children.length === 0) {
  const Comp = flexBlocks[typeHandle];
  if (!Comp) {
    return (
      <div
        className={`${typeHandle} colorScheme--bg-${colorScheme}`}
        style={{ backgroundColor: color }}
      >
        {typeHandle !== "flexBlock" && `missing component for ${typeHandle}`}
        <FlexChildren children={children}></FlexChildren>
        {borderArt && (
          <>
            <div className="border-art left"></div>
            <div className="border-art right"></div>
          </>
        )}
      </div>
    );
  }
  return (
    <>
      {/* {console.log(entry)} */}

      <Comp {...entry} className={`${typeHandle}`} overlap={overlapTop}>
        <FlexChildren children={children}></FlexChildren>
      </Comp>
    </>
  );
};
//   console.log("children", children, typeHandle);
//   return (
//     <>
//       <div className={typeHandle}>
//         <div className="debug">{typeHandle}</div>
//         <FlexChildren children={children}></FlexChildren>
//       </div>
//     </>
//   );
//};

const FlexChildren = ({ children }) => {
  return (
    <>
      {children &&
        children.map((child, index) => (
          <FlexBlock key={index} {...child} entry={child}></FlexBlock>
        ))}
    </>
  );
};

export default FlexBlock;
