import React, { useState, useContext } from "react";

const Text = ({ richText }) => {
  //const { copyFont, headingFont, styles, siteHandle } = useContext(SiteContext);
  //console.log("COPY font", copyFont, styles);

  //   let match = useRouteMatch();
  //   const history = useHistory();

  return (
    <>
      {/* {block.popout.length && (
        <Link
          to={`${match.url}/${block.popout[0].slug}`}
          style={{ color: "white" }}
        >
          MODAL LINK
        </Link>
      )}
      {/* <ReactTypeformEmbed url="https://ericwang191886.typeform.com/to/ts9US2" /> */}

      <div
        // copyFont={copyFont}
        // headingFont={headingFont}
        className="rich-text"
        dangerouslySetInnerHTML={{
          __html: richText,
        }}
        // style={{
        //   columnCount: block.autoColumns ? `2` : `1`,
        // }}
      ></div>

      {/* block.slideout.length && (
        <div>
          {block.slideout[0] != undefined ? block.slideout[0].title : "undef "}

          <Slideout slug={block.slideout[0].slug}>
            {block.slideout[0] != undefined ? block.slideout[0].title : "undef "}
          </slideout>
        </div>
      )}{" "} */}
    </>
  );
};

export default Text;
