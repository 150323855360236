import React, { useState, useEffect } from "react";
import HeroBlocks from "components/hero-blocks";
import Blocks from "components/blocks";
import usePage from "./usePage";
import { NavLink, useLocation, Redirect } from "react-router-dom";
import SeomaticHelmet from "components/SeomaticHelmet";

const Page = ({ match }) => {
  const parent = match.params.parent;

  const { pathname } = useLocation();
  const location = useLocation();

  console.log("Page", match);

  const { loading, error, data } = usePage(
    parent && parent !== "blog" ? parent : match.params.slug
  );
  useEffect(() => {
    // console.log("Page location", location);
    // setPrevPage(location);
    if (location.state === null) window.scrollTo(0, 0);
  }, [pathname]);

  // if (loading && parent === undefined) {
  //   return <>loading</>;
  // }
  if (error) {
    return <>error</>;
  }
  if (!data) {
    return null;
  }
  const entry = data.entry;

  if (!entry) {
    console.log(data, match);
    return <>404 not found</>;
  }

  const { contentBlocks, heroBlocks, ...rest } = entry;

  return (
    <div className="page">
      <SeomaticHelmet seomatic={data.seomatic} />
      <HeroBlocks blocks={heroBlocks}></HeroBlocks>
      <Blocks blocks={contentBlocks} entry={rest}></Blocks>
    </div>
  );
};

export default Page;
