import React from "react";

const FlexColumn = ({ mobileDisplayOrder, children }) => {
  console.log("COLUMNCHECK", mobileDisplayOrder);
  const displayOrder = mobileDisplayOrder ? mobileDisplayOrder : 1;
  return (
    <div className={`contentColumn order-${displayOrder}`}>{children}</div>
  );
};

export default FlexColumn;
