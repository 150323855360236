import React, { useState } from "react";
import useLocations from "./useLocations";
import { isMobileOnly } from "react-device-detect";
import Slideout from "components/slideout/";
import MobileSlider from "components/mobile-slider/";
import { NavLink } from "react-router-dom";
import ResponsiveImage from "components/responsive-image";
import "./locations.scss";

const Locations = () => {
  const { loading, error, data } = useLocations();
  if (loading) {
    return <>loading locations</>;
  }
  if (error || !data) {
    return <>{console.log("error loading locations")}</>;
  }
  if (data.entries.length < 1) {
    return <>{console.log("no locations found")}</>;
  }

  const states = data.entries.map((location, index) => {
    const locationImage = location.image.length ? location.image[0] : null;
    return (
      <article className="location__tile tile" key={index}>
        <div className="location__heading">
          <div className="location__name">
            <h1>{location.title}</h1>
          </div>
        </div>
        <div className="location__details">
          <div className="location__image">
            <div className="image">
              {locationImage && (
                <ResponsiveImage imageObject={locationImage}></ResponsiveImage>
              )}
              <div className="more">Learn More</div>
            </div>
          </div>
          <div className="location__footer">
            <div dangerouslySetInnerHTML={{ __html: location.richText }}></div>
          </div>
        </div>
        <NavLink
          to={{ pathname: `/${location.uri}` }}
          className="tile__link"
        ></NavLink>
      </article>
    );
  });
  const slideouts = data.entries.map((location, index) => {
    return (
      <Slideout
        key={index}
        slug={location.slug}
        entry={location}
        id={location.id}
        type={location.typeHandle}
      ></Slideout>
    );
  });

  return (
    <div>
      {isMobileOnly ? (
        <MobileSlider>{states}</MobileSlider>
      ) : (
        <div className="container tiles">{states}</div>
      )}

      {slideouts}
    </div>
  );
};

export default Locations;
