import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";
import { image, contentBlocks, seomatic } from "helpers";

const inner = `typeHandle
        title
        slug
        heroBlocks{
          ...on heroBlocks_heroBanner_BlockType{
            image {
              ${image}
            } 
            heading
            subheading
          }
        }
        ${contentBlocks}
        `;

const query = gql`
  query($slug: [String], $uri: String, $siteId:String) {
    entry(slug: $slug, siteId: $siteId) {
      ... on pages_page_Entry {
       ${inner}
      }
    }
    
    ${seomatic}
  }
`;

const usePage = (slug) => {
  if (!slug) {
    slug = "__home__";
  }

  let variables = {
    slug,
    siteId: "1",
    uri: typeof window !== "undefined" ? window.location.pathname : null,
  };

  return useQuery(query, { variables: variables });
};

export default usePage;
