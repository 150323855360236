import React from "react";
import ResponsiveImage from "components/responsive-image";

function Image({ image, aspectRatio, border }) {
  if (image.length < 1) {
    return <>missing image</>;
  }
  const color = border && border.length > 0 ? border[0].borderColor : null;

  return (
    <div className={`img ${color ? "has-border bg--" + color : ""}`}>
      {console.log("out", image, border, aspectRatio)}
      <ResponsiveImage
        imageObject={image[0]}
        aspectRatio={aspectRatio}
      ></ResponsiveImage>
    </div>
  );
}

export default Image;
