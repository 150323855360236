import React, { memo } from "react";
import {
  ComposableMap,
  Geographies,
  Geography,
  Annotation,
  Marker,
} from "react-simple-maps";
import { geoCentroid } from "d3-geo";
import allStates from "./data/allstates.json";

const geoUrl = "https://cdn.jsdelivr.net/npm/us-atlas@3/states-10m.json";
const offsets = {
  VT: [50, -8],
  NH: [34, 2],
  MA: [30, -1],
  RI: [28, 2],
  CT: [35, 10],
  NJ: [34, 1],
  DE: [33, 0],
  MD: [47, 10],
  DC: [49, 21],
};
// const locations = [{ title: "Connecticut" }, { title: "New York" }];

const Map = ({ setTooltipContent, locations }) => {
  return (
    <ComposableMap projection="geoAlbersUsa" data-tip="">
      <Geographies geography={geoUrl}>
        {({ geographies }) => (
          <React.Fragment>
            {geographies.map((geo) => {
              const cur = locations.find(
                (s) => s.title === geo.properties.name
              );
              const abbr = allStates.find((s) => s.val === geo.id);

              console.log(cur, geo.properties.name, geo);

              return (
                <Geography
                  data-tip={geo.properties.NAME}
                  key={geo.rsmKey}
                  stroke="#FFF"
                  geography={geo}
                  fill={cur ? "#FDBE68" : "#FED8A4"}
                  //   style={{
                  //     hover: {
                  //       fill: cur ? "#ccc" : "#FDBE68",
                  //     },
                  //   }}
                  onMouseEnter={() => {
                    if (cur) {
                      const { name } = geo.properties;
                      const children = cur.children.length
                        ? "<ul>" +
                          cur.children
                            .map(
                              (place) => `<li>
                        <span class="name">${place.title}</span>
                        <span class="city">${place.city}, ${abbr.id}</span>
                      </li>`
                            )
                            .join("") +
                          "</ul>"
                        : "Relationship in Progress";

                      setTooltipContent(`<h3>${name}</h3><div><ul>
                      
                    ${children}</ul> </div>`);
                    }
                  }}
                  onMouseLeave={() => {
                    setTooltipContent("");
                  }}
                />
              );
            })}
            {/* {geographies.map((geo) => {
              const centroid = geoCentroid(geo);
              const cur = allStates.find((s) => s.val === geo.id);
              return (
                <g key={geo.rsmKey + "-name"}>
                  {cur &&
                    centroid[0] > -160 &&
                    centroid[0] < -67 &&
                    (Object.keys(offsets).indexOf(cur.id) === -1 ? (
                      <Marker coordinates={centroid}>
                        <text y="2" fontSize={14} textAnchor="middle">
                          {cur.id}
                        </text>
                      </Marker>
                    ) : (
                      <Annotation
                        subject={centroid}
                        dx={offsets[cur.id][0]}
                        dy={offsets[cur.id][1]}
                      >
                        <text x={4} fontSize={14} alignmentBaseline="middle">
                          {cur.id}
                        </text>
                      </Annotation>
                    ))}
                </g>
              );
            })} */}
          </React.Fragment>
        )}
      </Geographies>

      {/* {twentytwenty.map((data, { University, Coordinates }) => (
          <MapMarker
            university={University}
            coordinates={Coordinates}
            data={data}
            key={data["University"]}
          />
        ))} */}
    </ComposableMap>
  );
};

export default memo(Map);
